<template>
  <div class="container">
    <title-bar :title-stack="titleStack"/>

    <div class="columns is-mobile">
      <div class="column">
        <aside class="menu" style="padding: 20px;">
          <p class="menu-label"> Menu Master </p>
          <ul class="menu-list">
            <li><a @click="selectTab(1)" class="labelss" :class="{'active' : activeTab === 1}">Master Skill</a></li>
            <li><a @click="selectTab(2)" class="labelss" :class="{'active' : activeTab === 2}">Master Sesi</a></li>
            <li><a @click="selectTab(3)" class="labelss" :class="{'active' : activeTab === 3}">Master User</a></li>
            <li><a @click="selectTab(4)" class="labelss" :class="{'active' : activeTab === 4}">Master Role User</a></li>
            <li><a @click="selectTab(5)" class="labelss" :class="{'active' : activeTab === 5}">Master Permission User</a></li>
            <li><a @click="selectTab(6)" class="labelss" :class="{'active' : activeTab === 6}">Master Bank & Pph</a></li>
            <li><a @click="selectTab(7)" class="labelss" :class="{'active' : activeTab === 7}">Master Client</a></li>
            <li><a @click="selectTab(8)" class="labelss" :class="{'active' : activeTab === 8}">Master Address</a></li>
          </ul>
        </aside>
      </div>

      <div class="column is-9">

          <div class="columns">
            <div class="column is-9-dekstop is-11-mobile">
            <div v-show="activeTab === 1">
              <skill />
            </div>
            <div v-show="activeTab === 2">
              <sesi />
            </div>
            <div v-show="activeTab === 3">
              <user />
            </div>
            <div v-show="activeTab === 4">
              <jabatan />
            </div>
            <div v-show="activeTab === 5">
              <permission />
            </div>
            <div v-show="activeTab === 6">
              <biaya-bank-pph />
            </div>
            <div v-show="activeTab === 7">
              <client-master></client-master>
            </div>
            <div v-show="activeTab === 8">
              <address-master></address-master>
            </div>
          </div>
          </div>
      </div>

    </div>

  </div>
</template>

<script>
import moment from 'moment'
import User from './User.vue'
import Sesi from './Sesi.vue'
import Skill from './Skill.vue'
import Jabatan from './Jabatan.vue'
import Permission from './Permission.vue'
import TitleBar from '@/components/TitleBar'
import BiayaBankPph from './BiayaBankPph.vue'
import ClientMaster from '../client/Index.vue'
import AddressMaster from './master-address/AddressMaster.vue'

export default {
  name: 'PengaturanIndex',
  components: {
    TitleBar,
    User,
    Sesi,
    Skill,
    Jabatan,
    Permission,
    BiayaBankPph,
    AddressMaster,
    // VirtualAccount,
    ClientMaster
  },
  methods: {
    searchOnClick () {},
    setFormat (v) {
      return moment(v).format('DD MMMM YYYY')
    },
    selectTab (val) {
      this.activeTab = val
    }
  },
  data () {
    return {
      activeTab: 1
    }
  },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Pengaturan'
      ]
    }
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
  .labelss {
    &.active {
      color: hsl(348, 83%, 47%);
    }
  }
</style>
