import axios from 'axios'

const state = () => ({})

const mutations = {}

const getters = {}

const actions = {
  async apiFormMasterAddress ({ commit }, data) {
    try {
      const payload = {
        name: data.name,
        address: data.address,
        reference_address: data.reference_address,
        building_id: data.building_id,
        latitude: data.lat,
        longitude: data.lng
      }
      const result = await axios.post(`${data.url}/${process.env.VUE_APP_ADDRESS_MASTER}`, payload)
      return result
    } catch (error) {
      throw new Error(error)
    }
  },
  async listAddressApi ({ commit }, data) {
    try {
      const result = await axios.get(`${data.url}/${process.env.VUE_APP_ADDRESS_MASTER}`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  },
  async detailAddressApi ({ commit }, data) {
    try {
      const result = await axios.get(`${data.url}/${process.env.VUE_APP_ADDRESS_MASTER}/${data.id}`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  },
  async deleteListAddressApi ({ commit }, data) {
    try {
      const result = await axios.delete(`${data.url}/${process.env.VUE_APP_ADDRESS_MASTER}/${data.id}`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  },
  async apiBuildingType ({ commit }, payload) {
    try {
      const result = await axios.get(`${payload}/api/v1/vendor/building_type`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  },
  async apiMaps ({ commit }, payload) {
    try {
      const result = await axios.get(`${payload.url}/api/v1/vendor/map?place=${payload.searchAddress}`)
      return result
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
