
import _ from 'lodash'
import axios from 'axios'

const state = () => ({
  new: null
})

const getters = {
  getSesi: (state, getters, rootState) => {
    return state.dataSesi
  },
  getNewProject: (state, getters, rootState) => {
    return state.new
  },
  getRescheduleProject: (state, getters, rootState) => {
    return state.new
  }
}

const actions = {
  setNewProject ({ commit, state }, project) {
    commit('newProject', project)
  },
  setRescheduleProject ({ commit, state }, project) {
    commit('rescheduleProject', project)
  },
  insertTransactionProducts ({ commit, state }, products) {
    commit('setTransactionProduct', products)
  },
  gettingImageProducts ({ commit, state }) {
    _.forEach(state.new.transactionProduct.listProduct, (v, i) => {
      setTimeout(() => {
        axios.get(v.i_uri)
          .then((cb) => {
            const product = { image_uri: cb.data.data.url, index: i }
            commit('setImageUri', product)
          })
      }, 500)
    })
  }

}

const mutations = {
  newProject (state) {
    state.new = {
      idtransaction_item: '',
      address_id: null,
      name_address: '',
      address: '',
      amount: 0,
      fee_building: 0,
      biaya_parkir: 0,
      net_amount: 0,
      customer_name: '',
      email: '',
      project_name: '',
      description: '',
      product_id: [],
      service_id: [],
      duration: '',
      start_date: '',
      end_date: '',
      jenis_bangunan: '',
      building_id: '',
      latitude: -6.175381728927934,
      longitude: 106.8269489488614,
      user_id: 0,
      vendor_id: 1,
      detail_sesi: [],
      item: [],
      transaction_id: '',
      branch: 1,
      customer_id: null,
      schedules: [],
      is_pph: 0,
      client_id: null
    }
  },

  rescheduleProject (state) {
    state.new = {
      project_id: '',
      detail_sesi_resch: []
    }
  },

  setCustomerId (state, val) {
    state.new.customer_id = val
  },
  setInfo (state, info) {
    state.new.idtransaction_item = info.idtransaction_item
    state.new.user_id = info.user_id
    state.new.transaction_id = info.transaction_id
  },
  setInfoDate (state, info) {
    state.new.duration = info.duration
    state.new.start_date = info.start_date
    state.new.end_date = info.end_date
  },
  setItem (state, items) {
    state.new.item = items
  },
  setProductId (state, products) {
    state.new.product_id = products
  },
  setServiceId (state, services) {
    state.new.service_id = services
  },
  setAmount (state, total) {
    state.new.amount = total.amount
    state.new.net_amount = total.net_amount
    state.new.fee_building = total.fee_building
  },
  setCustomer (state, customer) {
    state.new.customer_name = customer.name
    state.new.cell_phone = customer.cell_phone
  },
  setTransactionProduct (state, products) {
    state.new.transactionProduct.listProduct = products
  },
  setTransactionProductSelected (state, transaction) {
    state.new.transaction_id = transaction.id
    state.new.transactionProduct.transaction = transaction.products
  },
  setAddress (state, val) {
    state.new.address = val.address
    state.new.longitude = val.lng
    state.new.latitude = val.lat
    state.new.jenis_bangunan = val.jenis_bangunan
    state.new.building_id = val.building_id
    state.new.name_address = val.name_address
    state.new.address_id = val.address_id
  },
  setImageUri (state, product) {
    state.new.transactionProduct.listProduct[product.index].image_uri = product.image_uri
  },
  setDetailSesi (state, val) {
    state.new.detail_sesi.push(val)
  },
  clearDetailSesi (state) {
    state.new.detail_sesi = []
  },
  clearAddress (state) {
    state.new.address = ''
    state.new.longitude = ''
    state.new.latitude = ''
    state.new.jenis_bangunan = ''
    state.new.building_id = ''
  },
  setItemTukang (state, items) {
    state.new.item = items
  },
  clearItemTukang (state) {
    state.new.item = []
  },
  setDescription (state, description) {
    state.new.description = description
  },
  setBiayaParkir (state, biayaParkir) {
    state.new.biaya_parkir = biayaParkir
  },
  setIsPph (state, isPph) {
    state.new.is_pph = isPph
  },
  setIsClient (state, isClientId) {
    state.new.client_id = isClientId
  },
  setProjectName (state, isProjectName) {
    state.new.project_name = isProjectName
  },
  setSchedule (state, data) {
    state.new.skills = data.skills
    state.new.products = data.products
    state.new.schedules = data.schedules
  },
  setDetailSesiReschedule (state, val) {
    state.new.detail_sesi_resch.push(val)
  },
  clearDetailSesiReschedule (state, val) {
    state.new.detail_sesi_resch = []
  },
  setProjectId (state, val) {
    state.new.project_id = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
