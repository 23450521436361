<template>
  <div>
    <div class="columns is-mobile mt-2">
      <div class="column is-narrow">
        <b-button @click="tambahOnClick()" type="is-success" icon-left="plus">Tambah Address</b-button>
      </div>
    </div>
    <div class="columns">
      <b-table
        :total="paginate.total"
        :per-page="paginate.perPage"
        :data="paginate.data"
        :loading="paginate.loading"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        paginated
        backend-pagination
        scrollable>
        <!-- <b-table-column field="id" label="#" v-slot="props">
          <b-checkbox v-model="props.row.isSelect" />
        </b-table-column> -->
        <b-table-column field="name" label="Nama Lokasi" v-slot="props">
          <div> {{ props.row.name }} </div>
        </b-table-column>
        <b-table-column field="address" label="Address" v-slot="props">
          {{ props.row.address }}
        </b-table-column>
        <b-table-column field="building_type" label="Building Type" v-slot="props">
          {{ props.row.building_type }}
        </b-table-column>
        <b-table-column field="act" label="Action" v-slot="props">
          <div class="buttons">
            <b-button icon-left="information" @click="detailOnClick(props.row)" type="is-primary" />
            <b-button @click="deleteList(props.row)" icon-left="delete" type="is-danger"/>
          </div>
        </b-table-column>
      </b-table>
    </div>

    <!-- Modal Tambah Address -->
    <ModalBase
      :is-active="addAddress"
      :is-width="widthModalForm"
      @cancel="closeModalAdd"
    >
      <template v-slot:header>
        <h6 style="font-weight: bold">Tentukan Address</h6>
      </template>
      <template v-slot:body>
        <FormAddAddress
          @cancel="closeModalAdd"
        />
      </template>
    </ModalBase>

    <ModalBase
      :is-active="detailAddress"
      :is-width="widthModalForm"
      @cancel="closeModalDetail"
    >
      <template v-slot:header>
        <h6 style="font-weight: bold">Detail Address</h6>
      </template>
      <template v-slot:body>
        <DetailMasterAddress :detail-data="dataDetailAddress"/>
      </template>
    </ModalBase>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormAddAddress from './FormAddAddress.vue'
import ModalBase from '../../../components/ModalBase.vue'
import DetailMasterAddress from './DetailMasterAddress.vue'

export default {
  name: 'AddressMaster',
  data () {
    return {
      paginate: {
        total: 0,
        loading: false,
        page: 1,
        perPage: 5,
        data: []
      },
      addAddress: false,
      widthModalForm: false,
      detailAddress: false,
      dataDetailAddress: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
    // hasSelected () {
    //   return this.paginate.data.find((o) => o.isSelect === true)
    // }
  },
  components: {
    ModalBase,
    FormAddAddress,
    DetailMasterAddress
  },
  watch: {},
  methods: {
    tambahOnClick () {
      this.addAddress = true
      this.widthModalForm = true
    },
    detailOnClick (item) {
      if (item) {
        const data = {
          url: this.url,
          id: item.id
        }
        this.$store.dispatch('address/detailAddressApi', data)
          .then((response) => {
            if (response.data.code === 1) {
              this.dataDetailAddress.push(response.data.data)
            }
          }).catch((error) => {
            this.$buefy.snackbar.open({
              message: error.response.data.message,
              queue: false
            }, 900)
          })
      }
      this.detailAddress = true
      this.widthModalForm = true
    },
    closeModalAdd () {
      this.addAddress = false
      this.fetchDataAddress()
    },
    closeModalDetail () {
      this.dataDetailAddress = []
      this.detailAddress = false
    },
    deleteList (item) {
      this.$buefy.dialog.confirm({
        message: `Hapus address terpilih <b>${item.name}</b> ?`,
        onConfirm: () => {
          const data = {
            url: this.url,
            id: item.id
          }

          this.$store.dispatch('address/deleteListAddressApi', data)
            .then((response) => {
              if (response.data.code === 1) {
                this.$buefy.toast.open({
                  duration: 1100,
                  message: response.data.message,
                  type: 'is-success'
                })
                this.fetchDataAddress()
              }
            }).catch((error) => {
              this.$buefy.snackbar.open({
                message: error.response.data.message,
                queue: false
              }, 900)
            })
        }
      })
    },
    onPageChange (v) {
      this.fetchDataAddress(v)
    },
    fetchDataAddress (v) {
      const data = {
        url: this.url
      }
      this.$store.dispatch('address/listAddressApi', data)
        .then((response) => {
          if (response.data.code === 1) {
            this.paginate.data = response.data.data
            this.paginate.total = (response.total_page * this.paginate.perPage)
            this.paginate.page = response.current_page
            this.paginate.loading = false
          }
        }).catch((error) => {
          this.$buefy.snackbar.open({
            message: error.response.data.message,
            queue: false
          }, 900)
        })
    }
  },
  created () {
    this.fetchDataAddress()
  }
}
</script>

<style lang="scss" scoped>
  .project-btn {
    color: green;
    cursor: pointer;
  }
</style>
