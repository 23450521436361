<template>
  <div>
    <div v-for="item in detailData" :key="item.id">
      <p class="title"> {{ item.name }}</p>
      <div class="columns">
        <div class="column">
          <GmapMap
            :center="{ lat: Number(item.latitude), lng: Number(item.longitude) }"
            :zoom="15"
            :options="{ disableDefaultUI: true }"
            map-type-id="roadmap"
            class="googleMapsView"
          >
            <GmapMarker :position="{ lat: Number(item.latitude), lng: Number(item.longitude) }" />
          </GmapMap>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="has-text-weight-semibold">
            {{ item.address }}
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <span class="mt-5">
            <p class="has-text-weight-bold">Patokan/ Catatan Khusus</p>
            <p class="has-text-weight-normal">
              {{ item.reference_address }}
            </p>
          </span>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <span class="mt-5">
            <p class="has-text-weight-bold">
              Jenis Bangunan
            </p>
            <div class="columns mt-2">
              <div class="column">
                <div class="card">
                  <div class="columns m-1">
                    <div class="column is-flex is-justify-content-center">
                      <figure class="image is-96x96 has-text-centered">
                        <img class="" src="../../../assets/rumah.png">
                      </figure>
                    </div>
                  </div>
                  <div class="columns has-text-centered">
                    <div class="column m-2">
                      <p class="has-text-weight-bold">{{ item.building_type }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DetailMasterAddress',
  data () {
    return {
      titleBuilding: null,
      typeRumah: '../../../assets/rumah.png',
      buildingType: null,
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      }
    }
  },
  props: ['detailData'],
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
    // imagePath () {
    //   let isType
    //   this.detailData.forEach(element => {
    //     isType = element.building_type
    //   })
    //   console.log(isType)
    //   if (isType === 'Rumah') {
    //     this.buildingType = '../../assets/rumah.png'
    //   }

    //   if (isType === 'Apartment') {
    //     this.buildingType = '../../assets/apartemen.png'
    //   }

    //   if (isType === 'Kantor') {
    //     this.buildingType = '../../assets/kantor.png'
    //   }

    //   if (isType === 'Ruko') {
    //     this.buildingType = '../../assets/ruko.png'
    //   }
    // }
  },
  components: {},
  watch: {},
  methods: {},
  created () {},
  updated () {}
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
}
.googleMapsView {
  max-width: 100%;
  height: 200px;
}
</style>
