<template>
  <b-modal
    :active.sync="isModalActive"
    :can-cancel="false"
    has-modal-card
  >
    <div class="modal-card" v-bind:style="isModalWidth ? myStyles : ''">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="header">
            Default Header
          </slot>
        </p>
      </header>
      <section class="modal-card-body">
        <slot name="body"> Default body content </slot>
      </section>
      <footer class="modal-card-foot">
        <button class="button has-text-right" type="button" @click="cancel">Cancel</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalBox',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      isModalWidth: false,
      myStyles: {
        width: '55vw'
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  },
  watch: {
    isActive (newValue) {
      this.isModalActive = newValue
    },
    isModalActive (newValue) {
      if (!newValue) {
        this.cancel()
      }
    },
    isWidth (newVal) {
      this.isModalWidth = newVal
    },
    isModalWidth (newVal) {
      if (!newVal) {
        this.isModalWidth = false
      }
    }
  }
}
</script>
