<template>
  <div id="app">
    <nav-bar/>
    <aside-menu/>
    <router-view/>
    <footer-bar />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import NavBar from '../src/components/NavBar'
import AsideMenu from '../src/components/AsideMenu'
import FooterBar from '../src/components/FooterBar.vue'

export default {
  name: 'App',
  components: {
    AsideMenu,
    NavBar,
    FooterBar
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isLogin: 'auth/isLogin',
      email: 'auth/email',
      user: 'auth/user',
      roles: 'auth/roles',
      rolePath: 'auth/rolePath'
    })
  },
  methods: {
    sessionClear () {
      sessionStorage.clear()
    },
    getSaldoMitra () {
      const is = this
      if (is.user !== null && is.user !== undefined) {
        setTimeout(function () {
          axios.get(is.url + '/api/v1/vendor/ending_balance')
            .then((data) => {
              is.$store.commit('setup/setUserSaldo', data.data.data)
            }).catch((err) => {
              console.log(err)
            })
        }, 500)
      }
    },
    checkLogin () {
      this.$store.commit('setup/setUrl', process.env.VUE_APP_SHELL_URL_API)

      const rs = localStorage.getItem('webapp_b2b_imajihaus')
      const auth = JSON.parse(rs)

      if (auth && auth.token) {
        this.$store.commit('auth/setLoginStatus', true)
        this.$store.commit('auth/setToken', auth.token)
        this.$store.commit('auth/setEmail', auth.email)
        this.$store.commit('auth/setUser', auth.user)
        axios.defaults.headers.common.Token = auth.token
        this.$store.dispatch('auth/rolesUser', { role_id: this.user.role_id, url: this.url })
      } else {
        this.$store.commit('auth/setLoginStatus', false)
        this.$router.push({ name: 'Login' })
      }
    }
  },
  created () {
    this.checkLogin()
    this.getSaldoMitra()
  },
  mounted () {}
}
</script>
