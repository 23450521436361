<template>
  <div>
    <div v-show="!viewState" class="content">
      <b-field label="Nama Lokasi">
        <b-input v-model="nameLocation"></b-input>
      </b-field>
      <b-field label="Lokasi di Peta">
        <div class="columns is-mobile">
          <div class="column">
            <b-button
              class="is-primary is-outlined m-2"
              @click="mapOnViewClick()"
              type="is-success"
              icon-left="map-marker-plus">
              Pilih Lokasi
            </b-button>

            <div v-if="map.currentPlace.lat && map.mapSelectedIsShow">
              <GmapMap
                style="max-width: 100%; height: 200px;"
                map-type-id="roadmap" :zoom="15"
                :center="map.currentPlace"
                :options="{disableDefaultUI: true}">
                <GmapMarker :position="map.currentPlace"></GmapMarker>
              </GmapMap>
            </div>
          </div>
        </div>
      </b-field>
      <b-field label="Alamat">
        <b-input class="has-text-black has-text-weight-bold"
          v-model="addressRef"
          minlength="10"
          maxlength="200"
          type="textarea"
          disabled>
        </b-input>
      </b-field>
      <b-field label="Deskripsi Lokasi">
        <b-input
          v-model="addressReference"
          minlength="10"
          maxlength="200"
          type="textarea">
        </b-input>
      </b-field>
      <b-field label="Jenis Bangunan">
        <div class="columns">
          <div class="column" v-for="(v, i) in buildingType" :key="i">
            <div class="card" :class="{'card-selected': v.isSelect === true}">
              <figure
                class="m-2"
                @click="setBuildingType(i)"
              >
                <img class="image is-32x32 centerImgs" :src="v.image_uri" />
                <figcaption>
                  <p class="has-text-centered">{{ v.name }}</p>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </b-field>
      <div class="columns mt-4">
        <div class="column">
          <b-button
            :class="{
              'disabled-button has-text-weight-bold': !isDisabled,
              'is-primary has-text-weight-bold': isDisabled,
            }"
            :disabled="!isDisabled"
            @click.prevent="submitForm()"
            rounded
            expanded>
            Proses
          </b-button>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="viewState" class="card">
        <div class="card-content">
          <b-field label="Cari Alamat">
            <b-autocomplete
              :data="map.address"
              placeholder="Telusuri Alamat"
              field="title"
              :loading="map.isFetchingAddress"
              @typing="fetchApiMaps"
              @select="option => map.addressSelected = option">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small> {{ props.option.address }} </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <GmapMap map-type-id="roadmap" :zoom="15"
            :center="map.gmapCenter"
            style="width: auto; height: 400px">
            <GmapInfoWindow
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false"
            >
              <div>
                <button
                  @click="simpanLokasiOnClick()"
                  class="button is-success">
                    Simpan Lokasi
                </button>
              </div>
            </GmapInfoWindow>
            <GmapMarker
              :position="map.markerPosition"
              :clickable="true"
              @dragend="updateCoordinate"
              @click="mapMarkerOnClick()"
              :draggable="true">
            </GmapMarker>
          </GmapMap>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormAddAddress',
  data () {
    return {
      viewState: false,
      nameLocation: null,
      buildingType: [],
      addressReference: null,
      addressRef: '',
      isMapOnView: false,
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      map: {
        currentPlace: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        markerPosition: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        address: null,
        isFetchingAddress: false,
        addressSelected: null,
        gmapCenter: {
          lat: -6.175381728927934,
          lng: 106.8269489488614
        },
        mapSelectedIsShow: false
      }
    }
  },
  props: ['detailData'],
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isDisabled () {
      let activeButton = false
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          activeButton = true
        }
      })
      return activeButton
    }
  },
  components: {},
  watch: {
    'map.addressSelected' (newVal, oldVal) {
      this.refreshAllCoordinate(newVal)
    }
  },
  methods: {
    close () {
      this.$emit('cancel')
    },
    simpanLokasiOnClick () {
      this.viewState = !this.viewState
    },
    mapOnViewClick () {
      if (this.map.mapSelectedIsShow === true) {
        const x = JSON.parse(JSON.stringify(this.map.currentPlace))
        this.map.markerPosition = x
        this.map.gmapCenter = x
      }
      this.infoWinOpen = false
      this.viewState = true
    },
    mapMarkerOnClick () {
      this.infoWindowPos = this.map.currentPlace
      this.infoWinOpen = !this.infoWinOpen

      const latLngObj = {
        lat: this.map.markerPosition.lat,
        lng: this.map.markerPosition.lng
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.addressRef = response.results[0].formatted_address
      })
      this.map.mapSelectedIsShow = true
    },
    updateCoordinate (location) {
      this.map.currentPlace.lat = location.latLng.lat()
      this.map.currentPlace.lng = location.latLng.lng()
      this.getAddressMap(location)
    },
    getAddressMap (location) {
      // const geocoder = this.google.maps.Geocoder()
      const latLngObj = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.addressRef = response.results[0].formatted_address
        // console.info(response.results)
      })
    },
    refreshAllCoordinate (newVal) {
      this.infoWinOpen = false
      this.map.gmapCenter = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.map.markerPosition = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      // this.updateCoordinate()
      this.map.currentPlace.lat = newVal.lat
      this.map.currentPlace.lng = newVal.lon
      const latLngObj = {
        lat: newVal.lat,
        lng: newVal.lon
      }
      this.$geocoder.setDefaultMode('lat-lng')
      this.$geocoder.send(latLngObj, response => {
        this.addressRef = response.results[0].formatted_address
      })
    },
    setBuildingType (idx) {
      this.buildingType.forEach((rw, _idx) => {
        const changeIsSelect = idx === _idx ? this.buildingType[_idx].isSelect = !this.buildingType[_idx].isSelect : this.buildingType[_idx].isSelect = false
        return changeIsSelect
      })
    },
    fetchApiBuildingType () {
      const url = this.url
      this.$store.dispatch('address/apiBuildingType', url)
        .then((cb) => {
          const x = []
          cb.data.data.forEach((rw) => {
            const r = rw
            r.isSelect = false
            x.push(r)
          })
          this.buildingType = x
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchApiMaps (val) {
      const payload = {
        url: this.url,
        searchAddress: val
      }
      this.$store.dispatch('address/apiMaps', payload)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.map.address = cb.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitForm () {
      let buildingId = null
      this.buildingType.forEach((rw, _idx) => {
        if (rw.isSelect === true) {
          buildingId = rw.id
        }
      })

      const addr = {
        url: this.url,
        name: this.nameLocation,
        address: this.addressRef,
        lat: this.map.currentPlace.lat.toString(),
        lng: this.map.currentPlace.lng.toString(),
        building_id: buildingId,
        reference_address: this.addressReference
      }

      this.$store.dispatch('address/apiFormMasterAddress', addr)
        .then((cb) => {
          if (cb.data.code === 1) {
            this.$buefy.toast.open({
              duration: 900,
              message: `Create New Address ${cb.data.data.name}`,
              type: 'is-success'
            })
            this.close()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    this.fetchApiBuildingType()
  },
  updated () {}
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
  background: #ffffff;
}
.card:hover, .card:focus {
  box-shadow: 0 0 10px #ccc;
  border: 1px solid #ccc;
  cursor: pointer;
}
.card-selected {
  box-shadow: inset 0 0 10px #ccc;
  border: 1px solid #ccc;
}
.disabled-button {
  background: #9a9a9a;
  font-size: 0.875rem;
  color: #ffffff;
}

</style>
