
// import axios from 'axios'
// import config from '../../api/config'
import axios from 'axios'
const state = () => ({
  email: null,
  token: null,
  loginStatus: false,
  user: null,
  roles: null,
  rolePath: null
})

const getters = {
  isLogin: (state, getters, rootState) => {
    return state.loginStatus
  },
  token: (state, getters, rootState) => {
    return state.token
  },
  email: (state, getters, rootState) => {
    return state.email
  },
  user: (state, getters, rootState) => {
    return state.user
  },
  roles: (state, getters, rootState) => {
    return state.roles
  },
  rolePath: (state, getters, rootState) => {
    return state.rolePath
  }
}

const actions = {
  insertToken ({ commit, state }, token) {
    commit('setToken', token)
  },
  login ({ commit, state }, user) {
    localStorage.removeItem('webapp_b2b_imajihaus')
    return new Promise((resolve, reject) => {
      axios.post(user.url + '/api/v1/vendor/login', {
        email: user.email,
        password: user.password,
        branch: user.branch.branch,
        branch_id: user.branch.branch_id
      }).then((response) => {
        const rs = {
          email: user.email,
          token: response.data.data[0].token,
          user: response.data.data[0]
        }
        axios.defaults.headers.common.Token = response.data.data[0].token
        localStorage.setItem('webapp_b2b_imajihaus', JSON.stringify(rs))
        localStorage.setItem('reloaded', 0)
        localStorage.setItem('isSign', 1)
        commit('setLoginStatus', true)
        commit('setEmail', user.email)
        commit('setUser', response.data.data[0])
        resolve(response)
      }).catch((error) => {
        localStorage.removeItem('webapp_b2b_imajihaus')
        commit('setLoginStatus', false)
        reject(error)
      })
    })
  },
  logout ({ commit, state }) {
    localStorage.removeItem('webapp_b2b_imajihaus')
    localStorage.removeItem('reloaded')
    commit('setLoginStatus', false)
  },
  rolesUser ({ commit, state }, roleUsr) {
    setTimeout(function () {
      axios.get(roleUsr.url + '/api/v1/vendor/permission_role?role_id=' + roleUsr.role_id)
        .then((cb) => {
          commit('setRoles', cb.data.data[0].role_name)
          commit('setPath', cb.data.data[0].data_permision)
        })
        .catch((err) => {
          console.log(err)
        })
    }, 1000)
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setToken (state, token) {
    state.token = token
  },
  setLoginStatus (state, status) {
    state.loginStatus = status
  },
  setEmail (state, email) {
    state.email = email
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  setPath (state, rolePath) {
    state.rolePath = rolePath
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
