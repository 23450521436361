import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import numeral from 'numeral'
import VCalendar from 'v-calendar'
import JsonExcel from 'vue-json-excel'
import Geocoder from '@pderas/vue2-geocoder'
import numFormat from 'vue-filter-number-format'
import * as VueGoogleMaps from 'vue2-google-maps'
import Autocomplete from '@trevoreyre/autocomplete-vue'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'

/* Default title tag */
const defaultDocumentTitle = 'Shell B2B Kanggo'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

axios.defaults.headers.common['API-KEY'] = process.env.VUE_APP_API_KEY
axios.defaults.headers.Authorization = process.env.VUE_APP_HEADERS_AUTHORIZATION
store.commit('setup/setUrl', process.env.VUE_APP_SHELL_URL_API)

Vue.use(Buefy)
Vue.use(JsonExcel)
Vue.component('downloadExcel', JsonExcel)
Vue.filter('numFormat', numFormat(numeral))
Vue.use(VCalendar)
Vue.use(Autocomplete)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  }
})
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: 'lat-lng', // or 'lat-lng'
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
